body {
  background-color: whitesmoke;
}

.price-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 2em;
  margin-bottom: 2em;
  padding: 0 16em;
}

.price {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: inherit;
  width: 300vw; 
  height: 45vh; 
  width: 15%;
  padding: 10px;
  box-sizing: border-box;
  transition: all 0.3s ease;
  background-color: white
}

.price:hover {
  transform: scale(1.05);
  box-shadow: 0px 10px 10px rgba(0,0,0,0.2);
}

.price h1 {
  font-size: 7em;
  color: #5E17EB;
}

.price h2 {
  font-size: 5em;
}

.price h3 {
  font-size: 2.7em;
  font-weight: normal;
}

.price p {
  font-size: 1em;
}