.search-bar-pro {
    width: auto;
    display: flex;
    justify-content: center;

    position: fixed;
 
}
.container-search {
    left: 80px;
    display: flex;
    width: 100%;
    position: fixed;
}
.tools-2 {
    right: 380px;
    display: flex;
    justify-content: center;
    position: fixed;
    padding-right: 10px;

}
.edit-btn-product {
    right: 120px;
    display: flex;
    float: right;
    justify-content: center;
    position:fixed;
}
.search-icon {
    display: flex;
    justify-content: center;
    position: fixed;

}
.add-btn-product {
    right: 265px;
    display: flex;
    float: right;
    justify-content: center;
    position: fixed;
    padding-right: 10px;


}