.search-bar {
    width: auto;
    display: flex;
    justify-content: center;

    position: fixed;
 
}
.container-search {
    left: 80px;
    right: 120px;
    display: flex;
    width: 86%;
    height: 2.5rem;
    position: fixed;
    margin-left:'140px';
    margin-right: '120px';
}
.tools {
    right: 335px;
    display: flex;
    justify-content: center;
    position: fixed;
    padding-right: 20px;
}
.edit-btn-clients {
    right: 120px;
    display: flex;
    float: right;
    justify-content: center;
    position:fixed;

}
.search-icon {
    display: flex;
    justify-content: center;
    position: fixed;
    right: 2rem;

}
.add-btn-clients {
    right: 245px;
    display: flex;
    float: right;
    justify-content: center;
    position: fixed;
    padding-right: 10px;

}

.download-btn {
    right: 0px;
    display: flex;
    justify-content: center;
    position:fixed;

}