.chatbot {
    width: 80px;
    height: 80px;
    position: fixed;
    right: 2rem;
    bottom: 2rem;
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-end;
}

.chatbot-button {
    width: 60px;
    height: 60px;
    font-size: 1.5rem;
    border: none;
    border-radius: 50%;
    padding: 0.5rem;
    background: #8F00FF;
    color: white;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.chatbot-chat {
    width: 300px;
    height: 500px;
    border: 1px solid #ccc;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    color: black
}

.chatbot-messages {
    padding: 1rem;
    overflow-y: auto;
    background-color: white;
}

.chatbot-input {
    z-index: 88888;
    border-top: 1px solid #ccc;
    padding: 1rem;
    display: flex;
}

.chatbot-input input {
    z-index: 88888;
    flex-grow: 1;
    border: none;
    padding: 0.5rem;
}

.chatbot-message {
    margin-bottom: 1rem;
}

.chatbot-message-user {
    
    align-self: flex-end;
    background: #8F00FF;
    color: white;
    border-radius: 5px;
    padding: 0.5rem;
}

.chatbot-message-bot {
    align-self: flex-start;
    background: #ccc;
    border-radius: 5px;
    padding: 0.5rem;
}

.chatbox__image--header {
    margin-right: 10px;
}