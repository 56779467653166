* {
    margin:0;
    padding: 0;
}

.navbar {
    background-color: rgb(18, 2, 31);
    margin-top: 0rem;
    height: 80px;
    display: flex;
    justify-content: start;
    align-items: center;
}


.menu-bars {
    margin-left: 1.5rem;
    font-size: 2rem;
    background: none;
    
}



.nav-menu {
    /*background-color: rgb(18, 2, 31);*/
    top:0;
    background-color:#222222;
    width: 80px;
    height: 100%;
    display: flex;
    justify-content: center;
    position: fixed;
    transition: 300ms;
    z-index: 1;

    
}

.nav-menu.active {
    width: 240px;
    transition: 300ms;
    border-radius: 0px 40px 40px 0px;

}

.nav-text {
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 8px 0px 8px 0px;
    list-style: none;
    height: 80px;

}

.nav-text a {
    text-decoration: none;
    color: aliceblue;
    font-size: 28px;
    width: 95%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 10px 0px 10px 27px;
    border-radius: 5px;
    font-family: 'Lato', sans-serif;
}

.nav-text a:hover {
    /*background-color: #362b4d ;*/
    background-color: #2f2e2e;
    text-decoration: none;
    color: aliceblue;
    
}

.nav-text a:active {
    /*background-color: #362b4d ;*/
    background-color: #3f0247;
    text-decoration: none;
    color: aliceblue;
    
}

.nav-menu-items {
    width: 100%;
}

.navbar-toggle {
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: start;
    padding: 10px 0px 10px 0px;
    align-items: center;
}
.company-name {
    text-decoration: none;
    color: aliceblue;
    font-size: 22px;
    width: 95%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 10px 0px 0px 18px;
    border-radius: 5px;
    font-family: 'Arial';   
    font-weight: 400;
}

.credentials {
    text-decoration: none;
    color: aliceblue;
    font-size: 16px;
    height: 100%;
    display: flex;
    display: inline-block;
    align-items: center;
    padding: 0px 0px 10px 18px;
    border-radius: 5px;
    font-family: 'Lato', sans-serif;
    font-weight: 600;

}
.role-nav {
    text-decoration: none;
    color: aliceblue;
    font-size: 14px;
    height: 100%;
    display: flex;
    display: inline-block;

    align-items: center;
    padding: 0px 0px 0px 5px;
    border-radius: 5px;
    font-family: 'Lato', sans-serif;

}
.nav-text.mode {
    position: absolute;
    bottom: 100;
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: start;
    align-items: center;
}

.nav-text.out {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: start;
    align-items: center;
}

.span2 {
    margin-left: 16px;
}

